import { format } from 'date-fns';

import { AvailabilityFormType } from '@/components/search/filters/AvailabilityFilter/types';
import { MeetingRoomFilterType } from '@/components/search/filters/MeetingRoomFilter/types';
import { SearchQueryParams } from '@/components/search/useSearchPageParams';
import { MeetingRoomFilterDto } from '@/lib/meeting-rooms/dto/meeting-room-filter.dto';
import { StringBool } from '@/models/StringBool.enum';
import { MinMaxFilter } from '@/types/MinMaxFilter';

export function createFilterQueryParams(
  filters: MeetingRoomFilterType
): SearchQueryParams {
  const { seats, amenities, dateTime } = filters;
  return {
    amenities: getAmenitiesQuery(amenities),
    seats: getSeatsQuery(seats),
    ...getDateQuery(dateTime),
  };
}

export function getAmenitiesQuery(amenities: string[] | undefined) {
  return amenities ? amenities.join(',') : undefined;
}

export function getSeatsQuery(seats: MinMaxFilter[] | undefined) {
  return seats
    ? seats.map(({ min, max }) => `${min}-${max ?? '+'}`).join(',')
    : undefined;
}

export function getDateQuery(dateTime: MeetingRoomFilterType['dateTime']) {
  if (!dateTime) {
    return {
      day: undefined,
      startTime: undefined,
      endTime: undefined,
      isAllDay: undefined,
    };
  }

  const { date, startTime, endTime, isAllDay } = dateTime;
  return {
    day: date ? format(date, 'y-MM-dd') : undefined,
    isAllDay: isAllDay ? StringBool.True : undefined,
    startTime: startTime ?? undefined,
    endTime: endTime ?? undefined,
  };
}

export function getFilterFromQueryParams(
  queryParams: SearchQueryParams
): MeetingRoomFilterType {
  return {
    amenities: getAmenitiesFromQuery(queryParams.amenities),
    seats: getSeatsFromQuery(queryParams.seats),
    dateTime: getDateTimeFromQuery(queryParams),
  };
}

export function getAmenitiesFromQuery(amenities: string | undefined) {
  return amenities ? amenities.split(',') : undefined;
}

export function getSeatsFromQuery(seats: string | undefined) {
  return seats
    ? seats.split(',').map((seat) => {
        const [min, max] = seat.split('-').map((value) => +value);
        return { min, max: isNaN(max) ? undefined : max };
      })
    : undefined;
}

export function getDateTimeFromQuery(queryParams: SearchQueryParams) {
  const { day, startTime, endTime, isAllDay } = queryParams;
  return day
    ? {
        date: new Date(`${day}T00:00:00`),
        startTime,
        endTime,
        isAllDay: isAllDay === StringBool.True,
      }
    : undefined;
}

function getAvailabilityFilters({
  date,
  startTime,
  endTime,
}: AvailabilityFormType): Pick<MeetingRoomFilterDto, 'dateRange' | 'day'> {
  if (date == null) return {};
  const day = format(date, 'y-MM-dd');

  return {
    day: !startTime ? day : undefined,
    dateRange:
      startTime && endTime
        ? {
            localStartDate: `${day}T${startTime}:00`,
            localEndDate: `${day}T${endTime}:00`,
          }
        : undefined,
  };
}

export function mapFilterToDto(
  filter: MeetingRoomFilterType
): MeetingRoomFilterDto {
  const { dateTime, ...rest } = filter;

  return {
    ...rest,
    ...(dateTime ? getAvailabilityFilters(dateTime) : {}),
  };
}
