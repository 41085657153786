import { Popover } from '@industriousoffice/react-components';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { Fragment, useEffect, useMemo, useState } from 'react';

import { HorizontalDivider } from '@/components/HorizontalDivider';
import { SEATS_FILTERS } from '@/components/search/filters/MeetingRoomFilter/types';
import seatsIcons from '@/public/icons/seatsIcon.svg';
import { MinMaxFilter } from '@/types/MinMaxFilter';

import FilterButton, { FilterButtonStyle } from './FilterButton';
import FilterCtas from './FilterCtas';
import { FilterOption } from './FilterOption';

import clsx from 'clsx';

type MeetingRoomSeatsFilterProps = {
  value: MinMaxFilter[] | undefined;
  onChange: (value: MinMaxFilter[] | undefined) => void;
  icon?: string;
  buttonStyle?: FilterButtonStyle;
  buttonClassName?: string;
};

export default function MeetingRoomSeatsFilter({
  value,
  onChange,
  icon,
  buttonStyle,
  buttonClassName,
}: MeetingRoomSeatsFilterProps) {
  const t = useTranslations('search');
  const [isPopoverOpen, setIsPopperOpen] = useState(false);

  const options = SEATS_FILTERS.map((x) => ({
    label: t(`seats-filter-options.${x.min}-${x.max ?? '+'}`),
    value: x,
  }));

  const [preselectedValues, setPreselectedValues] = useState<MinMaxFilter[]>(
    []
  );

  const valueLabel = useMemo(() => {
    if (!value) return t('seats');

    return value
      .sort((a, b) => (a.min ?? 0) - (b.min ?? 0))
      .map((v) => options.find((x) => x.value.min === v.min)?.label)
      .join(', ');
  }, [value]);

  useEffect(() => {
    if (value) {
      setPreselectedValues(value);
    } else {
      setPreselectedValues([]);
    }
  }, [value]);

  const onClickOption = (value: MinMaxFilter) => {
    if (preselectedValues.some((x) => x.min === value.min)) {
      setPreselectedValues(
        preselectedValues.filter((x) => x.min !== value.min)
      );
    } else {
      setPreselectedValues([...preselectedValues, value]);
    }
  };

  const onApply = () => {
    setIsPopperOpen(false);

    if (value === undefined && preselectedValues.length === 0) {
      return;
    }

    if (preselectedValues.length === 0) {
      return onChange(undefined);
    }

    onChange(preselectedValues);
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onApply();
    }

    setIsPopperOpen(isOpen);
  };

  const Content = () => (
    <div className="p-5 pb-3">
      <div className="flex flex-wrap gap-2 w-[290px]">
        {options.map((v) => (
          <Fragment key={v.label}>
            <FilterOption
              className="flex-1 text-sm"
              onClick={() => onClickOption(v.value)}
              isActive={preselectedValues.some((x) => x.min === v.value.min)}
              label={v.label}
            />
          </Fragment>
        ))}
      </div>
      <HorizontalDivider className="mt-2" />
      <FilterCtas
        onClearLink={() => setPreselectedValues([])}
        onApplyBtn={onApply}
        dataTestId="seats"
      />
    </div>
  );

  return (
    <Popover
      className={clsx(isPopoverOpen && 'top-[13px]')}
      isOpen={isPopoverOpen}
      onOpenChange={handleOpenChange}
      align="start"
      content={<Content />}
    >
      <FilterButton
        className={buttonClassName}
        data-testid="seats-select-filter"
        icon={<Image src={icon || seatsIcons} alt="Seats icon" />}
        buttonStyle={buttonStyle}
      >
        {valueLabel}
      </FilterButton>
    </Popover>
  );
}
