import { useMemo } from 'react';

import { getFilterFromQueryParams } from '@/app/[locale]/(search)/meeting-rooms/_components/utils';

import { MeetingRoomFilterType } from './filters/MeetingRoomFilter/types';
import useSearchPageParams from './useSearchPageParams';

export default function useMeetingRoomFilters() {
  const { queryParams } = useSearchPageParams();

  const filter: MeetingRoomFilterType = useMemo(() => {
    return getFilterFromQueryParams(queryParams);
  }, [
    queryParams.amenities,
    queryParams.seats,
    queryParams.day,
    queryParams.startTime,
    queryParams.endTime,
    queryParams.isAllDay,
  ]);

  return {
    filter,
  };
}
