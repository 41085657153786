import Image from 'next/image';
import React from 'react';

import closeIcon from '@/public/icons/closeIcon.svg';

import clsx from 'clsx';

type FilterButtonProps = {
  icon: JSX.Element;
  isActive?: boolean;
  buttonStyle?: FilterButtonStyle;
  onClearClick?: () => void;
} & React.ComponentPropsWithoutRef<'button'>;

export enum FilterButtonStyle {
  DEFAULT = 'default',
  CHIP = 'chip',
}

const FilterButton = React.forwardRef<HTMLButtonElement, FilterButtonProps>(
  (
    {
      children,
      isActive,
      icon,
      onClearClick,
      className,
      buttonStyle = FilterButtonStyle.DEFAULT,
      ...rest
    },
    ref
  ) => {
    const handleClearClick = (e: React.MouseEvent<HTMLImageElement>) => {
      e.stopPropagation();
      onClearClick?.();
    };

    return (
      <button
        ref={ref}
        className={clsx(
          'flex items-center',
          buttonStyle === FilterButtonStyle.CHIP &&
            'px-4 py-[6px] border rounded-full border-grey-70 text-sm',
          isActive ? 'border-[2px] border-teal-40' : 'm-[1px]',
          className
        )}
        {...rest}
      >
        {icon}
        <span
          className={clsx(
            'truncate',
            buttonStyle === FilterButtonStyle.CHIP ? 'ml-1' : 'ml-2'
          )}
        >
          {children}
        </span>
        {isActive && (
          <Image
            className="ml-2"
            src={closeIcon}
            alt="Close Icon"
            width={12}
            height={12}
            onClick={(e) => handleClearClick(e)}
          />
        )}
      </button>
    );
  }
);
FilterButton.displayName = 'FilterButton';

export default FilterButton;
