import { LocationOpeningHoursDto } from '@/lib/locations/dto/location-opening-hours.dto';
import { BlackoutDateDto } from '@/lib/shared/dto/blackout-date.dto';
import { DateRange } from '@/models/DateRange';
import {
  DateAfter,
  DateBefore,
  DateInterval,
  DayOfWeek,
} from '@/types/DateFilter';

import { DAY_PASS_CUTOFF_HOUR } from './constants';
import { MAX_BOOKING_MONTHS } from './constants';
import {
  addDays,
  addMonths,
  createLocalDay,
  isAfter,
  setHours,
  setMinutes,
} from './date-utils';

const dayOfWeekMap = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export function getBlackoutDatesDayFilter(
  blackoutDays: BlackoutDateDto[]
): Date[] {
  return blackoutDays.map((blackoutDay) => createLocalDay(blackoutDay.date));
}

export function getUnavailableDaysFilter(unavailableDays: string[]): Date[] {
  return unavailableDays.map(createLocalDay);
}

export function getPastDayFilter(): DateBefore {
  return { before: new Date() };
}

export function getPastDayOrTodayFilter(): DateBefore {
  return { before: addDays(new Date(), 1) };
}

export function getDateFilterBeforeCutoff(): DateBefore {
  const today = new Date();

  const todayAtCutoff = setHours(setMinutes(today, 0), DAY_PASS_CUTOFF_HOUR);

  if (isAfter(today, todayAtCutoff)) {
    return { before: addDays(today, 1) };
  }

  return { before: addDays(today, 0) };
}

export function getDateRangeDayFilter(dateRange: DateRange): DateInterval {
  return { before: dateRange.from, after: dateRange.to };
}

export function getMaxMonthsFilter(months: number): DateAfter {
  const today = new Date();
  const dateAfterMonths = addMonths(today, months);
  return { after: dateAfterMonths };
}

export function getMaxDaysFilter(days: number): DateAfter {
  const today = new Date();
  const dateAfterDays = addDays(today, days);
  return { after: dateAfterDays };
}

export function getOpeningHoursDayFilter(
  openingHours: LocationOpeningHoursDto
): DayOfWeek {
  const openedDays = Object.keys(openingHours).filter(
    (day) => openingHours[day as keyof LocationOpeningHoursDto] == null
  );

  return {
    dayOfWeek: openedDays.map(
      (day) => dayOfWeekMap[day as keyof typeof dayOfWeekMap]
    ),
  };
}

export const weekEndDisabled = getOpeningHoursDayFilter({
  mon: { open: '09:00', close: '17:00' },
  tue: { open: '09:00', close: '17:00' },
  wed: { open: '09:00', close: '17:00' },
  thu: { open: '09:00', close: '17:00' },
  fri: { open: '09:00', close: '17:00' },
  sat: null,
  sun: null,
});

export const pastDaysOrTodayDisabled = getPastDayOrTodayFilter();

export const pastDaysBeforeCutoffDisabled = getDateFilterBeforeCutoff();
export const futureMonthsDisabled = getMaxMonthsFilter(MAX_BOOKING_MONTHS);
